<template>
    <div>
        <p v-if="!isEdit" class="message">
            <span v-if="categoryName === 'account'">
                <span v-if="getAccounts.length < 1">1/3. </span>Создайте или <span @click.prevent="$router.push('/import/account')" class="importLink">импортируйте</span> реальный счет, с которым Вы регулярно взаимодействуете. Например: Наличные, Кредитка, MonoBank.
            </span>
            <span v-else-if="categoryName === 'spend'">
                <span v-if="getSpending.length < 1">2/3. </span>Создайте категорию трат, на что Вы обычно тратите деньги. Например: Продукты, Машина, Путешествия.
            </span>
            <span v-else>
                <span v-if="getIncoming.length < 1">3/3. </span>Создайте категорию доходов, откуда Вы обычно получаете деньги. Например: Зарплата, Стипендия, Дивиденды.
            </span>
            <i class="far fa-info-circle"></i>
        </p>
        <form class="settings">
            <label v-if="categoryName === 'account'" for="">Название счета: </label>
            <label v-else for="">Название категории: </label>
            <input
                v-if="categoryName === 'account'"
                type="text"
                placeholder="Наличные"
                v-model="name"
            >
            <input
                v-else-if="categoryName === 'spend'"
                type="text"
                placeholder="Продукты"
                v-model="name"
            >
            <input
                v-else
                type="text"
                placeholder="Зарплата"
                v-model="name"
            >
            <label for="">Валюта: </label>
            <v-select
                :items="getCurrencyItems"
                :menu-props="{ offsetY: true, maxHeight: '50vh' }"
                label="Валюта"
                class="select"
                v-model="currency"
                solo
                flat
                rounded
            >
                <template v-slot:selection="{ item }" >
                    <v-list-item-content>
                        <v-list-item-title class="currency">
                            <i v-if="item.sign" :class="item.sign"></i><span v-else>{{ item.alias }}</span> {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
                <template v-slot:item="{ item }" >
                    <v-list-item-content>
                        <v-list-item-title class="currency">
                            <i v-if="item.sign" :class="item.sign"></i><span v-else>{{ item.alias }}</span> {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-select>

            <label v-if="categoryName === 'account'" for="">Текущий баланс: </label>
            <input v-if="categoryName === 'account'" type="number" step="any" placeholder="1 000,00" @keypress='validateAmout($event)' v-model="balance">

            <label for="">Целевой баланс: </label>
            <input type="number" step="any" placeholder="(не обязательно)" @keypress='validateAmout($event)' v-model="target">

            <label v-if="isEdit && categoryName === 'account'" for="">Импорт: </label>
            <input v-if="isEdit && cardNumber" type="text" step="any" @click="$router.push('/import/account?id=' + categoryId)" readonly :value="importAcc" :style="{ cursor: 'pointer' }">
            <fieldset v-else-if="isEdit && categoryName === 'account'" @click="$router.push('/import/account?id=' + categoryId)"><label class="importLink">Привязать реальный счет</label></fieldset>

            <template v-if="cardNumber">
                <label title="Загрузить выписку по счету, начиная с даты" for="">Выписка начиная с:</label>
                <fieldset>
                    <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="computedDateFormatted"
                                readonly
                                v-on="on"
                                class="calendar calendar--inline"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="updatedAt"
                            no-title
                            locale="ru"
                            :first-day-of-week="1"
                            :min="new Date(new Date() - 3600000*24*30).toISOString()"
                            @input="menu = false"
                        ></v-date-picker>
                    </v-menu>
                </fieldset>
            </template>

            <label>Скрыть из меню:</label>
            <v-radio-group v-model="isHidden" row class="fieldset">
                <v-radio label="Нет" class="flex-grow-1" :value="false"></v-radio>
                <v-radio class="flex-grow-1" label="Скрыть" :value="true"></v-radio>
            </v-radio-group>

            <label v-if="categoryName === 'account' || categoryName === 'spend'">Тип: </label>
            <v-radio-group v-if="categoryName === 'account' || categoryName === 'spend'" v-model="accType" row class="fieldset">
                <v-radio label="Личный" class="flex-grow-1" value="self"></v-radio>
                <v-radio class="flex-grow-1" label="Совместный" value="multi"></v-radio>
            </v-radio-group>

            <span v-if="(categoryName === 'account' || categoryName === 'spend') && accType === 'multi'" class="submit">
                <span class="accOwner" v-if="creator">
                    <label>Создатель: </label>
                    <input type="text" disabled :value="creator">
                    <label>Права: </label>
                    <v-row class="fieldset">
                        <v-checkbox class="flex-grow-1" label="Вносить данные" disabled input-value="true"></v-checkbox>
                        <v-checkbox class="flex-grow-1" label="Редактировать" disabled input-value="true"></v-checkbox>
                    </v-row>
                </span>
                <span class="accOwner" v-for="(owner, i) in accOwners" :key="i">
                    <label>Email совладельца: </label>
                    <input type="text" v-model="owner.email" placeholder="email" :disabled="owner.old">
                    <label>Права: </label>
                    <v-row class="fieldset">
                        <v-checkbox v-model="owner.write" label="Вносить данные" :disabled="owner.old" class="flex-grow-1"></v-checkbox>
                        <v-checkbox v-model="owner.edit" label="Редактировать" :disabled="owner.old" class="flex-grow-1"></v-checkbox>
                    </v-row>
                </span>

                <v-btn rounded large text class="newOwner" @click="addOwner()">
                    <v-icon left>fal fa-plus</v-icon> Добавить совладельца
                </v-btn>
            </span>

            <span class="submit">
                <input @click.prevent="$router.push('/category/' + categoryName + '/' + categoryId)" v-if="isEdit" class="second" type="submit" value="Отмена">
                <input @click.prevent="$router.push('/import/account')" v-else-if="categoryName === 'account'" class="second" type="submit" value="Импортировать">
                <span class="loading-wrap">
                    <half-circle-spinner
                        v-if="loading"
                        class="loading"
                        :animation-duration="1000"
                        :size="15"
                        :color="'#FFF'"
                    />
                    <input v-if="!isEdit" @click.prevent="formSubmited" class="main" type="submit" :value="(categoryName === 'account') ? 'Добавить счет' : 'Добавить категорию'">
                    <input v-else @click.prevent="formSubmited(false)" class="main" type="submit" value="Сохранить">
                </span>
            </span>
        </form>
        <transition name="fade">
            <p v-if="!isValid" class="message error">
                <span>{{ errorMsg || 'Вы заполнили не все обязательные поля!' }}</span>
                <i @click="isValid = true" class="far fa-times"></i>
            </p>
            <p v-else-if="successMsg" class="message">
                <span>
                    {{ (categoryName === 'account') ? 'Счет успешно создан!' : 'Категория успешно создана!' }}
                    {{ 'Добавьте еще ' }} {{ (categoryName === 'account') ? 'один ' : 'одну ' }}
                    {{ 'или вернитесь к ' }}
                    <a href="#" class="importLink" @click="$router.push('/')">Операциям.</a>
                </span>
                <i @click="successMsg = false" class="far fa-times"></i>
            </p>
        </transition>
    </div>
</template>

<script>
import $ from 'jquery';
import { mapActions, mapGetters } from 'vuex';
import { HalfCircleSpinner } from 'epic-spinners';
import EventBus from '@/event-bus';
import { get } from 'lodash';

export default {
    name: 'category-settings',
    components: {
        HalfCircleSpinner
    },
    data() {
        return {
            name: '',
            currency: 'USD',
            balance: null,
            target: null,
            isValid: true,
            errorMsg: '',
            loading: false,
            accType: 'self',
            accOwnersCount: 1,
            accOwners: [{
                email: '',
                write: true,
                edit: false,
            }],
            creator: '',
            isHidden: false,
            importAcc: null,
            cardNumber: null,
            successMsg: false,
            menu: false,
            updatedAt: ''
        }
    },
    computed: {
        ...mapGetters(["getAccounts","getSpending","getIncoming","currentUser","getCurrencyItems"]),
        categoryName() {
            return this.$route.params.name;
        },
        categoryId() {
            return this.$route.params.id || this.$route.name;
        },
        isEdit() {
            return this.categoryId !== "add-category";
        },
        getCategoryById() {
            let item;
            switch (this.categoryName) {
                case 'account':
                    item = this.getAccounts.filter((account) => account.id === Number(this.categoryId))[0];
                    break;
                case 'spend':
                    item = this.getSpending.filter((spend) => spend.id === Number(this.categoryId))[0];
                    break;
                case 'income':
                    item = this.getIncoming.filter((income) => income.id === Number(this.categoryId))[0];
                    break;
            }
            if (!item) {
                item = {
                    name: '',
                    currency: '',
                    balance: null,
                    target: null,
                };
            }

            return item;
        },
        computedDateFormatted() {
            if (!this.updatedAt) return null;

            const [year, month, day] = this.updatedAt.split('-');
            return `${day}.${month}.${year}`;
        }
    },
    async mounted() {
        if (this.categoryId > 0) {
            let category = this.getCategoryById;
            await this.getCategoryOwners([ category.id, category.uid, this.categoryName ]);

            this.name = category.name;
            this.currency = category.currency;
            this.balance = (category.balance * 100).toFixed() / 100;
            this.target = category.target;
            this.accType = category.accType;
            this.isHidden = category.isHidden;
            this.importAcc = (category.import === 'yandex') ? `ЮMoney ${category.cardNumber}` : `Monobank (${category.currency})`;
            this.cardNumber = category.cardNumber;
            this.updatedAt = category.updatedAt.substr(0, 10);
            if (get(category, 'accOwners.length')) {
                this.accOwners = category.accOwners.map((owner) => {
                    return {
                        ...owner,
                        old: true,
                    }
                });
                this.creator = category.creator;
            }
        } else {
            this.currency = this.currentUser.currency;
        }
    },
    methods: {
        ...mapActions(["addCategory", "getCategory", "editCategory", "getCategoryOwners"]),
        validateAmout(evt) {
            let theEvent = evt || window.event;
            // Handle paste
            let key;
            if (theEvent.type === 'paste') {
                key = event.clipboardData.getData('text/plain');
            } else {
                // Handle key press
                key = theEvent.keyCode || theEvent.which;
                key = String.fromCharCode(key);
            }
            let regex = /[0-9]|\.|,/;
            if (!regex.test(key) ) {
                theEvent.returnValue = false;
                if (theEvent.preventDefault) theEvent.preventDefault();
            }
        },
        validateName(type, name) {
            let categories = [];
            let categoryName = "Категория";
            switch (type) {
                case 'account':
                    categories = this.getAccounts;
                    categoryName = "Счет";
                    break;
                case 'spend':
                    categories = this.getSpending;
                    break;
                case 'income':
                    categories = this.getIncoming;
                    break;
            }
            let isAlreadyExist = categories.some((category) => category.name === name);
            if (isAlreadyExist) {
                this.errorMsg = `${ categoryName } с названием "${ name }" уже существует`;
                this.$showConfirmDialog({
                    title: `${ categoryName } с названием "${ name }" уже существует. Создать копию?`,
                    description: "",
                    approvedCallback: async () => {
                        try {
                            await this.formSubmited(false);
                        } catch (error) {
                            console.log(error);
                        }
                    },
                });
            }
            return isAlreadyExist;
        },
        async formSubmited(checkName = true) {
            this.loading = true;
            this.isValid = true;
            this.errorMsg = '';
            this.successMsg = false;

            // Validating
            if (this.name === '' || this.currency === '' || (checkName && this.validateName(this.categoryName, this.name))) {
                this.isValid = false;
                this.loading = false;
                return;
            }
            // End Validating

            let newCategoryData = {
                categoryName: this.categoryName,
                categoryId: this.categoryId,
                name: this.name,
                currency: this.currency,
                balance: parseFloat(String(this.balance).replace(',','.').replace(' ','')) || 0,
                target: parseFloat(String(this.target).replace(',','.').replace(' ','')) || null,
                isHidden: this.isHidden,
            };

            if (this.accOwners.length) {
                newCategoryData.accType = this.accType;
                newCategoryData.accOwners = this.accOwners.filter(owner => owner.email !== '' && !owner.old);
            }

            try {
                if (this.isEdit) {
                    newCategoryData.updatedAt = new Date(this.updatedAt).toISOString();
                    await this.editCategory(newCategoryData)
                    EventBus.$emit('UPDATE_BANKS_OPERATIONS');
                } else {
                    await this.addCategory(newCategoryData);
                }

                // clear all fields
                this.name = '';
                this.currency = this.currentUser.currency;
                this.balance = null;
                this.target = null;
                this.accType = 'self';
                this.accOwnersCount = 1,
                this.accOwners = [{
                    email: '',
                    write: true,
                    edit: false,
                }],
                this.isHidden = false;
                // redirect
                (this.isEdit) ? this.$router.go(-1) : this.successMsg = true;
            } catch (error) {
                this.isValid = false;
                this.errorMsg = error;
            }

            this.loading = false;
        },
        addOwner() {
            if (this.accOwnersCount < 30) {
                this.accOwners.push({ email: '', write: true, edit: false });
            } else {
                this.isValid = false;
            }
        }
    },
    watch: {
        isValid: function (value) {
            if (value) return;
            $('html, body').animate({
                scrollTop: $(document).scrollTop() + 100,
            }, 300);
        },
        successMsg: function (value) {
            if (!value) return;
            $('html, body').animate({
                scrollTop: $(document).scrollTop() + 100,
            }, 300);
        }
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    .loading-wrap {
        position: relative;
        .loading {
            position: absolute;
            left: 30px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }
    }
</style>
<style lang="scss">
    @import '@/assets/vars.scss';

    .settings .v-select .v-input__control .v-input__slot {
        border: $border;
    }
    .v-list-item__title.currency i, .select .v-select__selections .v-list-item__title.currency i {
        font-size: 14px;
    }
</style>

